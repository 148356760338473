.balloons {
    margin: 0;
}

.balloon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.top {
    height: 75px;
    width: 50px;
    border-radius: 50%;
}

.string {
    height: 75px;
    width: 2px;
    background-color: grey;
}