.App {
    display: grid;
    overflow: hidden;
    grid-template-rows: 5rem 1fr 1fr 1fr 5rem;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    user-select: none;
    position: relative;
}

.rainbow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.rainbowColor {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
}