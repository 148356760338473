.modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: rgb(0, 0, 0);
    padding: 2rem;
    border-radius: 5px;
    text-align: center;
}

.modalTitle {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}

.button {
    background-color: rgba(255, 255, 255, 0.267);
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin: 1rem;
    &:hover {
        background-color: #fff2;
    }
}