.carContainer {
    height: calc((100vw - 10rem) / 3);
    width: calc((100vw - 10rem) / 3);
    max-width: 150px;
    max-height: 150px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.car {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 3s linear;
    height: 50%;
    width: 100%;
}

.roof {
    display: grid;
    grid-template-columns: 1fr 10%;
    justify-items: center;
    align-items: center;
    margin-left: -10%;
    height: 45%;
    width: 50%;
    border-radius: 1rem 1rem 0 0;
    border: 0.5px solid grey;
    border-bottom: none;
}

.window {
    background-color: #00e1ff;
    width: 80%;
    height: 80%;
    border-radius: 1rem;
}

.windscreen {
    background-color: #00e1ff;
    width: 100%;
    height: 100%;
    border-radius: 0 1rem 0 0;
}

.body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50%;
    border-radius: 1rem;
    border: 0.5px solid grey;
}

.headLight {
    width: 5%;
    height: 5%;
    border-radius: 0 1rem 0 0;
    background-color: gold;
    position: absolute;
    right: 0;
}

.tailLight {
    width: 5%;
    height: 5%;
    position: absolute;
    border-radius: 1rem 0 0 0;
    background-color: darkred;
}

.wheels {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.backWheel {
    position: relative;
    top: 50%;
    left: 10%;
    background-color: #333;
    width: 25%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frontWheel {
    position: relative;
    top: 50%;
    right: 10%;
    background-color: #333;
    width: 25%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hubCap {
    background-color: #999;
    width: 20%;
    height: 20%;
    border-radius: 50%;
}